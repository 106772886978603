import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { campaignGameEnableToggle, campaignGameList } from '@src/clients/3thix';
import { CampaignGame, Error3thix, SuccessCampaignGameList, UpdateCampaignGameReq } from '@src/types';

import ModalCreate from './components/ModalCreate';
import ModalUpdate from './components/ModalUpdate';

type Props = {
  id: string;
};

const PAGE_SIZE = 20;

const Campaigns = ({ id }: Props) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [campaigns, setCampaigns] = useState<SuccessCampaignGameList>();
  const [updateModal, setUpdateModal] = useState<UpdateCampaignGameReq>();

  const listAll = useCallback(async () => {
    const { data, status } = await campaignGameList(id, page, pageSize);
    if (status === 200) {
      setCampaigns(data as SuccessCampaignGameList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [id, page, pageSize]);

  const toggleOpen = () => setOpenCreateModal((old) => !old);
  const toggleOpenUpdate = () => setUpdateModal(undefined);

  const onSuccess = useCallback(() => {
    setOpenCreateModal(false);
    setUpdateModal(undefined);
    listAll();
  }, [listAll]);

  useEffect(() => {
    listAll();
  }, [listAll]);

  const toggleEnable = useCallback(
    async (id, enabled) => {
      await campaignGameEnableToggle(id, enabled ? 'disable' : 'enable');
      listAll();
    },
    [listAll]
  );

  const rows = useMemo(() => {
    return campaigns ? campaigns.campaign_games : [];
  }, [campaigns]);

  const COLUMNS = useMemo(
    () => [
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: CampaignGame) => (
          <Flex align="flex-start" gap="small">
            <Tooltip title="Enable/Disable">
              <Button
                type="default"
                size="middle"
                icon={<i className="fa-solid fa-power-off"></i>}
                onClick={() => toggleEnable(record.id, record.enabled)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="default"
                size="middle"
                icon={<i className="fas fa-edit" />}
                onClick={() => {
                  setUpdateModal({
                    id: record.id,
                    max_redemption_per_user: record.max_redemption_per_user,
                    max_redemption_per_campaign: record.max_redemption_per_campaign,
                    start_date: record.start_date,
                    end_date: record.end_date,
                    source_account_id: record.source_account_id,
                    amount: record.amount,
                    currency: record.currency,
                  });
                }}
              />
            </Tooltip>
          </Flex>
        ),
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        render: (enabled: boolean) => {
          return enabled ? 'Yes' : 'No';
        },
      },
      { title: 'ID', dataIndex: 'id' },
      { title: 'Name', dataIndex: 'name' },
      { title: 'External Campaign ID', dataIndex: 'external_campaign_id' },
      { title: 'Amount', dataIndex: 'amount' },
      { title: 'Type', dataIndex: 'type' },
      { title: 'Campaign ID', dataIndex: 'campaign_id' },
      { title: 'Max redemption per campaign', dataIndex: 'max_redemption_per_campaign' },
      { title: 'Max redemption per user', dataIndex: 'max_redemption_per_user' },
      { title: 'Source Account ID', dataIndex: 'source_account_id' },
      { title: 'Currency', dataIndex: 'currency' },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: (start_date: string | null) => {
          if (start_date === null) return 'not defined';
          return new Date(start_date).toDateString();
        },
      },
      {
        title: 'End Date',
        dataIndex: 'end_date',
        render: (end_date: string | null) => {
          if (end_date === null) return 'not defined';
          return new Date(end_date).toDateString();
        },
      },
    ],
    [toggleEnable]
  );

  return (
    <div>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Button type="default" size="large" icon={<i className="fas fa-plus" />} onClick={toggleOpen}>
            Set Campaign
          </Button>
        </div>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && campaigns && (
          <Table
            dataSource={rows}
            rowKey={(index) => `row-${index}`}
            columns={COLUMNS}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: campaigns ? campaigns.total : 0,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>

      {updateModal && <ModalUpdate isOpen old={updateModal} toggleOpen={toggleOpenUpdate} onSuccess={onSuccess} />}
      {openCreateModal && (
        <ModalCreate id={id} isOpen={openCreateModal} toggleOpen={toggleOpen} onSuccess={onSuccess} />
      )}
    </div>
  );
};

export default Campaigns;
