import { theme, ThemeConfig } from 'antd';

export const THEME: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  components: {
    Menu: {
      darkItemBg: 'transparent',
      darkItemSelectedBg: 'transparent !important',
      darkItemSelectedColor: '#2af391 !important',
      fontSize: 15,
      itemPaddingInline: 20,
    },
    Modal: {
      contentBg: '#00002D',
      headerBg: '#00002D',
    },
    Table: {
      colorBgContainer: '#00002D',
      borderColor: '#1E1E43',
      borderRadius: 0,
      headerBorderRadius: 0,
      filterDropdownBg: '#000025',
      rowExpandedBg: '#000025',
      // rowExpandedBg: '#1b1b3f',
    },
    Pagination: {
      colorBgContainer: '#2af391',
      colorPrimary: '#00002D',
    },
    Input: {
      colorBgContainer: '#1E1E43',
      colorBorder: '#1E1E43',
    },
    Select: {
      colorBgContainer: '#1E1E43',
      colorBorder: '#1E1E43',
    },
    Segmented: {
      colorBgElevated: '#00002D',
    },
  },
  token: {
    colorPrimary: '#2af391',
    colorInfo: '#2af391',
    fontFamily: 'Poppins',
    colorBgContainer: '#00002D',
    colorBgLayout: '#1E1E43',
  },
};
