import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '@src/style.css';
import { App, ConfigProvider } from 'antd';

import { EntityProvider } from '@src/contexts/EntityContext';
import LoginWrapper from '@src/layout/LoginWrapper';
import Audit from '@src/pages/Audit';
import Game from '@src/pages/Game';
import Notifications from '@src/pages/Notifications';
import Order from '@src/pages/Order';
import Orders from '@src/pages/Orders';
import Publisher from '@src/pages/Publisher';
import Publishers from '@src/pages/Publishers';
import Root from '@src/pages/Root';
import User from '@src/pages/User';
import Users from '@src/pages/Users';
import reportWebVitals from '@src/reportWebVitals';
import { THEME } from '@src/theme';

import EntitySelector from './components/EntitySelector';
import Campaigns from './pages/Campaigns';
import ChargebackRequests from './pages/Chargeback';
import Ledger from './pages/Ledger';
import TransactionsLimits from './pages/TransactionsLimits';
import Vendors from './pages/Vendors';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Orders />,
  },
  {
    path: '/orders',
    element: <Orders />,
  },
  {
    path: '/orders/:id',
    element: <Order />,
  },
  {
    path: '/users',
    element: <Users />,
  },
  {
    path: '/users/:id',
    element: <User />,
  },
  {
    path: '/publishers',
    element: <Publishers />,
  },
  {
    path: '/publishers/:id',
    element: <Publisher />,
  },
  {
    path: '/publishers/:publisherId/games/:id',
    element: <Game />,
  },
  {
    path: '/root/:id',
    element: <Root />,
  },
  {
    path: '/audits',
    element: <Audit />,
  },
  {
    path: '/ledger',
    element: <Ledger />,
  },
  {
    path: '/notifications',
    element: <Notifications />,
  },
  {
    path: '/transactions-limits',
    element: <TransactionsLimits />,
  },
  {
    path: '/chargeback-requests',
    element: <ChargebackRequests />,
  },
  {
    path: '/campaigns',
    element: <Campaigns />,
  },
  {
    path: '/vendors',
    element: <Vendors />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ConfigProvider theme={THEME}>
      <App>
        <EntityProvider>
          <LoginWrapper>
            <EntitySelector>
              <RouterProvider router={router} />
            </EntitySelector>
          </LoginWrapper>
        </EntityProvider>
      </App>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
