import { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Skeleton, Table } from 'antd';
import Title from 'antd/es/typography/Title';

import { ledgerList } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { searchRangeDateFilterTable, searchTextFilterTable } from '@src/components/SearchFilterTable';
import { Error3thix, ListLedgerRequest, SuccessLedgerList } from '@src/types';

import { DEFAULT_SEARCH_VALUES, PAGE_SIZE } from './constants';

const Ledger = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [auditResp, setAuditResp] = useState<SuccessLedgerList>();
  const [search, setSearch] = useState<ListLedgerRequest>({
    search_by: {
      account_id: new URLSearchParams(window.location.search).get('accountId') || undefined,
      transaction_id: new URLSearchParams(window.location.search).get('transactionId') || undefined,
      ...DEFAULT_SEARCH_VALUES,
    },
  });

  const getAudits = useCallback(async () => {
    const { data, status } = await ledgerList(page, pageSize, search);

    if (status === 200) {
      setAuditResp(data as SuccessLedgerList);
    } else {
      message.error((data as Error3thix).message);
    }

    setIsLoading(false);
  }, [page, pageSize, search]);

  const onChangeSort = useCallback((field: string) => {
    setSearch((old) => {
      if (old.order_by && old.order_by.field === field) {
        return { ...old, order_by: old.order_by.order === 'DESC' ? { field, order: 'ASC' } : undefined };
      }

      return { ...old, order_by: { field, order: 'DESC' } };
    });
  }, []);

  useEffect(() => {
    getAudits();
  }, [getAudits]);

  const rows = useMemo(() => (auditResp ? auditResp.ledger_entries : []), [auditResp]);

  // id
  // account_id
  // credit
  // debit
  // provider
  // rail
  // currency
  // transcode_id
  // transaction_id
  // is_settlement
  // created_at
  const columns = useMemo(
    () => [
      { title: 'ID', dataIndex: 'id' },
      {
        title: 'Account ID',
        dataIndex: 'account_id',
        filterDropdown: searchTextFilterTable({
          setSearchText: (account_id) =>
            setSearch((old) => {
              old.search_by.account_id = account_id === null ? undefined : account_id;
              return { ...old };
            }),
          fieldName: 'Account ID',
        }),
      },
      {
        title: 'Credit',
        dataIndex: 'credit',
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => onChangeSort('credit'),
        }),
      },
      {
        title: 'Debit',
        dataIndex: 'debit',
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => onChangeSort('debit'),
        }),
      },
      {
        title: 'Provider',
        dataIndex: 'provider',
        filterDropdown: searchTextFilterTable({
          setSearchText: (provider) =>
            setSearch((old) => {
              old.search_by.provider = provider === null ? undefined : provider;
              return { ...old };
            }),
          fieldName: 'Provider',
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('provider');
          },
        }),
      },
      {
        title: 'Rail',
        dataIndex: 'rail',
        filterDropdown: searchTextFilterTable({
          setSearchText: (rail) =>
            setSearch((old) => {
              old.search_by.rail = rail === null ? undefined : rail;
              return { ...old };
            }),
          fieldName: 'Rail',
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('rail');
          },
        }),
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        filterDropdown: searchTextFilterTable({
          setSearchText: (currency) =>
            setSearch((old) => {
              old.search_by.currency = currency === null ? undefined : currency;
              return { ...old };
            }),
          fieldName: 'Currency',
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('currency');
          },
        }),
      },
      {
        title: 'Transcode ID',
        dataIndex: 'transcode_id',
        filterDropdown: searchTextFilterTable({
          setSearchText: (transcode_id) =>
            setSearch((old) => {
              old.search_by.transcode_id = transcode_id === null ? undefined : Number(transcode_id);
              return { ...old };
            }),
          fieldName: 'Transcode ID',
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('transcode_id');
          },
        }),
      },
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
        filterDropdown: searchTextFilterTable({
          setSearchText: (transaction_id) =>
            setSearch((old) => {
              old.search_by.transaction_id = transaction_id === null ? undefined : transaction_id;
              return { ...old };
            }),
          fieldName: 'Transaction ID',
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('transaction_id');
          },
        }),
      },
      {
        title: 'Is Settlement',
        dataIndex: 'is_settlement',
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('is_settlement');
          },
        }),
        render: (is_settlement: boolean) => {
          return is_settlement ? 'Yes' : 'No';
        },
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        render: (created_at: string) => {
          return new Date(created_at).toDateString();
        },
        filterDropdown: searchRangeDateFilterTable({
          setRange: (value) => setSearch((old) => ({ ...old, date_range: value })),
        }),
        sorter: true,
        onHeaderCell: () => ({
          onClick: () => {
            onChangeSort('created_at');
          },
        }),
      },
    ],
    [onChangeSort]
  );

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <Title level={2}>
          <i className="fa-solid fa-receipt"></i> Ledger
        </Title>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && auditResp !== null && (
          <Table
            dataSource={rows}
            columns={columns}
            scroll={{ x: '50%' }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: auditResp?.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default Ledger;
