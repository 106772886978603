import { useCallback, useEffect, useMemo, useState } from 'react';

import { App, Button, message, Modal, Select } from 'antd';
import Input from 'antd/es/input/Input';

import { accountList, campaignGameSet, campaignList } from '@src/clients/3thix';
import { CURRENCY_DESCRIPTIONS } from '@src/constants';
import { CreateCampaignGameReq, Error3thix, SuccessAccountList, SuccessCampaignList } from '@src/types';

import { defaultValue } from './constants';

type Props = {
  toggleOpen: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  id: string;
};

const ModalCreate = ({ id, toggleOpen, isOpen, onSuccess }: Props) => {
  const { modal } = App.useApp();
  const [formData, setFormData] = useState<CreateCampaignGameReq>({ ...defaultValue, game_entity_id: id });
  const [campaigns, setCampaigns] = useState<SuccessCampaignList>();
  const [accountsResp, setAccountsResp] = useState<SuccessAccountList | null>(null);

  const listAccounts = useCallback(async () => {
    const { data, status } = await accountList(1, 50, id);

    if (status === 200) {
      setAccountsResp(data as SuccessAccountList);
      return;
    }

    message.error((data as Error3thix).message);
  }, [id]);

  const listAll = useCallback(async (name: string) => {
    const { data, status } = await campaignList(name, 1, 5);
    if (status === 200) {
      setCampaigns(data as SuccessCampaignList);
      return;
    }

    message.error((data as Error3thix).message);
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { status, data } = await campaignGameSet(formData);
      if (status === 201) {
        modal.success({
          title: 'Campaign successfully set',
          onOk: onSuccess,
          footer: (_, { OkBtn }) => <OkBtn />,
        });
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, modal, onSuccess]
  );

  useEffect(() => {
    listAccounts();
    listAll('');
  }, [listAll, listAccounts]);

  const selectCampaignInput = useMemo(() => {
    return (
      <Select<string>
        showSearch
        size="large"
        placeholder="Select a transaction limit template"
        optionFilterProp="label"
        className="my-2 w-full"
        options={
          campaigns
            ? campaigns.campaigns.map((campaign) => ({
                label: campaign.name,
                value: campaign.id,
              }))
            : []
        }
        onSearch={(value) => listAll(value)}
        onChange={(value) => setFormData((old) => ({ ...old, campaign_id: value }))}
      />
    );
  }, [listAll, campaigns]);

  const selectAccountInput = useMemo(() => {
    return (
      <Select<string>
        showSearch
        size="large"
        placeholder="Account ID or Keep empty to use root minter account"
        optionFilterProp="label"
        className="my-2 w-full"
        options={
          accountsResp
            ? accountsResp.accounts.map((account) => ({
                label: `(${account.currency}${account.rail ? ' / ' + account.rail : ''}) ${account.name} - ${account.id}`,
                value: account.id,
              }))
            : []
        }
        // onSearch={(value) => listAll(value)}
        onChange={(value) => setFormData((old) => ({ ...old, source_account_id: value }))}
      />
    );
  }, [accountsResp]);

  const inputs = useMemo(() => {
    function input(name: string) {
      switch (name) {
        case 'type':
          return (
            <Select<string>
              showSearch
              size="large"
              placeholder="campaign type"
              optionFilterProp="label"
              className="my-2 w-full"
              options={[
                { label: 'ADS', value: 'ADS' },
                { label: 'WELCOME BONUS', value: 'WELCOME_BONUS' },
              ]}
              onChange={(value) => setFormData((old) => ({ ...old, type: value }))}
            />
          );
        case 'start_date':
        case 'end_date':
          return (
            <Input
              size="large"
              type="datetime-local"
              name={name}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                //2024-09-12T20:13\" as \"2006-01-02T15:04:05Z07:00
                const parsedValue = value + ':00Z';
                setFormData((prevFormData) => ({ ...prevFormData, [name]: parsedValue }));
              }}
            />
          );
        case 'max_redemption_per_campaign':
        case 'max_redemption_per_user':
          return (
            <Input
              required
              size="large"
              type="number"
              name={name}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                setFormData((prevFormData) => ({ ...prevFormData, [name]: Number(value) }));
              }}
            />
          );
        case 'currency':
          return (
            <Select
              aria-required
              size="large"
              placeholder="Select Currency"
              className="my-2 w-full"
              onChange={(value) =>
                setFormData((prevFormData) => ({ ...prevFormData, [name]: value === '' ? null : value }))
              }
            >
              {Object.keys(CURRENCY_DESCRIPTIONS).map((key) => (
                <Select.Option key={key} value={key}>
                  {CURRENCY_DESCRIPTIONS[key]}
                </Select.Option>
              ))}
            </Select>
          );
        default:
          return (
            <Input
              required
              size="large"
              type="text"
              name={name}
              className="my-2 w-full"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
              }}
            />
          );
      }
    }

    const removeInputs = (key) => key !== 'campaign_id' && key !== 'game_entity_id' && key !== 'source_account_id';

    return Object.keys(defaultValue)
      .filter(removeInputs)
      .map((key) => (
        <div key={`index-${key}`} className="flex flex-col w-[48%]">
          <label className="text-white font-[10px] ml-[10px]" htmlFor="country">
            {key.replaceAll('_', ' ')}
          </label>
          {input(key)}
        </div>
      ));
  }, []);

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={700}
      title={
        <>
          <i className="fa-solid fa-money-bill-transfer"></i> Set Campaign
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col">
            <label className="text-white font-[10px]">Campaigns</label>
            {selectCampaignInput}

            <label className="text-white font-[10px]">Source Account</label>
            {selectAccountInput}

            <div className="flex flex-row w-full flex-wrap justify-between">{inputs}</div>
          </div>

          <Button size="large" className="mt-8 w-full" htmlType="submit" icon={<i className="fas fa-check" />}>
            Set
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreate;
