import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, message, Skeleton, Table, Tooltip } from 'antd';

import { fulfillmentList } from '@src/clients/3thix';
import { Error3thix, Fulfillment, SuccessFulfillmentsList } from '@src/types';

const PAGE_SIZE = 10;

type Props = {
  orderId: string;
};

const FulfillmentsTable = ({ orderId }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [fulfillmentsResp, setFulfillmentsResp] = useState<SuccessFulfillmentsList>();

  const getFulfillments = useCallback(async () => {
    const { data, status } = await fulfillmentList(page, pageSize, orderId);

    if (status === 200) {
      setFulfillmentsResp(data as SuccessFulfillmentsList);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, orderId]);

  useEffect(() => {
    getFulfillments();
  }, [getFulfillments]);

  const rows = useMemo(() => {
    if (!fulfillmentsResp) {
      return [];
    }

    return fulfillmentsResp.fulfillments;
  }, [fulfillmentsResp]);

  return (
    <div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && fulfillmentsResp && (
          <Table
            dataSource={rows}
            scroll={{ x: '50%' }}
            columns={[
              {
                title: 'Actions',
                key: 'actions',
                render: (_: unknown, record: Fulfillment) => (
                  <Tooltip title="Ledger">
                    <Button
                      type="default"
                      size="middle"
                      icon={<i className="fas fa-book" />}
                      onClick={() => navigate(`/ledger?transactionId=${record.id}`)}
                    />
                  </Tooltip>
                ),
              },
              { title: 'id', dataIndex: 'id' },
              { title: 'Amount', dataIndex: 'amount' },
              { title: 'Currency', dataIndex: 'currency' },
              { title: 'Status', dataIndex: 'status' },
              { title: 'Source Account', dataIndex: 'source_account_id' },
              { title: 'Destination Account', dataIndex: 'destination_account_id' },
              { title: 'Error Message', dataIndex: 'error_msg' },
              {
                title: 'Created at',
                dataIndex: 'created_at',
                render: (created_at: string) => {
                  return new Date(created_at).toDateString();
                },
              },
              {
                title: 'Updated at',
                dataIndex: 'updated_at',
                render: (updated_at: string) => {
                  return new Date(updated_at).toDateString();
                },
              },
            ]}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: fulfillmentsResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FulfillmentsTable;
