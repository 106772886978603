import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal } from 'antd';

import { transferCreate } from '@src/clients/3thix';
import { CRYPTO_CURRENCY_LIST } from '@src/constants';
import { Account, Error3thix } from '@src/types';

type TransferData = {
  fulfillment_entity_id: string;
  fulfillment_account_id: string;
  currency: string;
  amount: string;
};

type Props = {
  account: Account | null;
  isOpen: boolean;
  toggleOpen: () => void;
  onRefresh: () => void;
};

const ModalTransfer = ({ account, isOpen, toggleOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<TransferData>({
    fulfillment_entity_id: '',
    fulfillment_account_id: '',
    currency: '',
    amount: '',
  });

  useEffect(() => {
    if (account) {
      setFormData({
        fulfillment_entity_id: account.owner_entity_id,
        fulfillment_account_id: account.id,
        currency: account.currency,
        amount: '0.0',
      });
    }
  }, [account]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await transferCreate(formData);

      if (status === 201 && 'invoice_id' in data) {
        const paymentWidgetUrl = process.env.REACT_APP_3THIX_PAY_WIDGET;

        const paymentUrl = `${paymentWidgetUrl}/?invoiceId=${data.invoice_id}`;

        const width = 500;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const popupSpecs = `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,left=${left},top=${top}`;

        window.open(paymentUrl, 'PaymentPopup', popupSpecs);

        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-exchange-alt"></i> Create Transfer
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <div className="w-full">
              <label htmlFor="currency">Currency</label>
              <Input
                disabled
                size="large"
                type="text"
                name="currency"
                value={CRYPTO_CURRENCY_LIST[formData.currency as keyof typeof CRYPTO_CURRENCY_LIST]}
                className="my-2 w-full"
              />
            </div>

            <label htmlFor="amount">Amount</label>
            <Input
              required
              size="large"
              type="text"
              name="amount"
              value={formData.amount}
              placeholder="Amount"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-exchange-alt" />}>
            Create Transfer
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalTransfer;
