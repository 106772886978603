import { useEffect, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { Button, Menu as MenuAnt } from 'antd';

import { rootRead } from '@src/clients/3thix';
import { useEntity } from '@src/contexts/EntityContext';
import { clearToken } from '@src/hooks/useLogin/utils';
import { THEME } from '@src/theme';

const logoutStyle = {
  backgroundColor: THEME.token?.colorPrimary,
  color: '#00002D',
  borderRadius: 50,
};

const AppMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [rootId, setRootId] = useState(String);
  const { selectedEntity, setSelectedEntity } = useEntity();

  useEffect(() => {
    const fetchRootId = async () => {
      const { data, status } = await rootRead();
      if (status === 200) {
        const id = data as string;
        setRootId(id);
      }
    };
    fetchRootId();
  }, []);

  const handleNavigate = (path: To) => {
    if (path === '/root' && rootId) {
      navigate(`/root/${rootId}`);
    } else {
      navigate(path);
    }
  };

  const handleOpenModal = () => {
    setSelectedEntity(null);
  };

  return (
    <MenuAnt
      defaultSelectedKeys={[location.pathname]}
      mode="horizontal"
      theme="dark"
      style={{ width: '100%', maxWidth: 1370 }}
      items={[
        {
          key: '/',
          label: 'Home',
          onClick: () => navigate('/'),
        },
        {
          key: '/publishers',
          label: 'Publishers',
          onClick: () => navigate('/publishers'),
        },
        {
          key: '/users',
          label: 'Users',
          onClick: () => navigate('/users'),
        },
        ...(selectedEntity?.type === 'ROOT'
          ? [
              {
                key: '/audits',
                label: 'Audits',
                onClick: () => navigate('/audits'),
              },
              {
                key: '/ledger',
                label: 'Ledger',
                onClick: () => navigate('/ledger'),
              },
              {
                key: '/root',
                label: '3thix',
                onClick: () => handleNavigate('/root'),
              },
              {
                key: '/notifications',
                label: 'Notifications',
                onClick: () => handleNavigate('/notifications'),
              },
              {
                key: '/transactions-limits',
                label: 'Limits',
                onClick: () => handleNavigate('/transactions-limits'),
              },
              {
                key: '/chargeback-requests',
                label: 'Chargebacks',
                onClick: () => handleNavigate('/chargeback-requests'),
              },
              {
                key: '/campaigns',
                label: 'Campaigns',
                onClick: () => handleNavigate('/campaigns'),
              },
              {
                key: '/vendors',
                label: 'Vendors',
                onClick: () => handleNavigate('/vendors'),
              },
            ]
          : []),
        {
          key: 'entity',
          label: selectedEntity ? (
            <Button type="link" style={{ color: 'inherit' }} onClick={handleOpenModal}>
              Entity: {selectedEntity.business_name}
            </Button>
          ) : (
            <Button type="link" style={{ color: 'inherit' }} onClick={handleOpenModal}>
              Select Entity
            </Button>
          ),
        },
        {
          key: '3',
          label: 'Logout',
          onClick: clearToken,
          style: logoutStyle,
        },
      ]}
    />
  );
};

export default AppMenu;
