import { useCallback, useState } from 'react';

import { Button, Checkbox, Input, message, Modal, Select } from 'antd';

import { accountCreate } from '@src/clients/3thix';
import { CURRENCY_DESCRIPTIONS, PROVIDER_RAIL_MAP, RAIL_CURRENCY_MAP, roleTypes } from '@src/constants';
import { AccountRole, Error3thix } from '@src/types';

type Props = {
  entityID: string;
  toggleOpen: () => void;
  isOpen: boolean;
  onRefresh: () => void;
};

const ModalCreateAccount = ({ entityID, toggleOpen, isOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState({
    owner_entity_id: entityID,
    name: '',
    provider: '',
    rail: '',
    currency: '',
    roles: [] as AccountRole[],
  });

  const availableRails = PROVIDER_RAIL_MAP[formData.provider as keyof typeof PROVIDER_RAIL_MAP] || [];
  const availableCurrencies = RAIL_CURRENCY_MAP[formData.rail as keyof typeof RAIL_CURRENCY_MAP] || [];

  const handleChangeSelect = useCallback((value: string | boolean, fieldName: string) => {
    if (typeof value === 'string') {
      if (fieldName === 'provider') {
        const rails = PROVIDER_RAIL_MAP[value as keyof typeof PROVIDER_RAIL_MAP];
        const defaultRail = rails[0];
        const currencies = RAIL_CURRENCY_MAP[defaultRail as keyof typeof RAIL_CURRENCY_MAP];
        const defaultCurrency = currencies[0];
        setFormData((old) => ({
          ...old,
          provider: value,
          rail: defaultRail,
          currency: defaultCurrency,
        }));
      } else if (fieldName === 'rail') {
        const currencies = RAIL_CURRENCY_MAP[value as keyof typeof RAIL_CURRENCY_MAP];
        const defaultCurrency = currencies[0];
        setFormData((old) => ({
          ...old,
          rail: value,
          currency: defaultCurrency,
        }));
      } else if (fieldName === 'currency') {
        setFormData((old) => ({ ...old, currency: value }));
      }
    }
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((old) => ({ ...old, [name]: value }));
  }, []);

  const handleRoleChange = useCallback((roleType: string, isChecked: boolean) => {
    setFormData((old) => {
      const roles = [...old.roles];
      if (isChecked) {
        roles.push({ type: roleType, is_default: false });
      } else {
        const index = roles.findIndex((role) => role.type === roleType);
        if (index !== -1) roles.splice(index, 1);
      }
      return { ...old, roles };
    });
  }, []);

  const handleDefaultChange = useCallback((roleType: string, isDefault: boolean) => {
    setFormData((old) => {
      const roles = old.roles.map((role) => (role.type === roleType ? { ...role, is_default: isDefault } : role));
      return { ...old, roles };
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await accountCreate(formData);

      if (status === 201) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user"></i> New Account
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="provider">
              Provider
            </label>
            <Select<string>
              size="large"
              value={formData.provider}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'provider')}
            >
              {Object.keys(PROVIDER_RAIL_MAP).map((provider) => (
                <Select.Option key={provider} value={provider}>
                  {provider}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="rail">
              Rail
            </label>
            <Select<string>
              size="large"
              value={formData.rail}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'rail')}
            >
              {availableRails
                .filter((rail): rail is string => typeof rail === 'string')
                .map((rail) => (
                  <Select.Option key={rail} value={rail}>
                    {rail}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="currency">
              Currency
            </label>
            <Select
              size="large"
              value={formData.currency}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'currency')}
            >
              {availableCurrencies
                .filter((currency): currency is string => typeof currency === 'string')
                .map((currency) => (
                  <Select.Option key={currency} value={currency}>
                    {CURRENCY_DESCRIPTIONS[currency]}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <div className="w-full">
            <label htmlFor="name">Name</label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              value={formData.name}
              placeholder="Account Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="roles">Roles</label>
            {roleTypes.map((roleType) => {
              const role = formData.roles.find((r) => r.type === roleType);
              return (
                <div key={roleType} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Checkbox checked={!!role} onChange={(e) => handleRoleChange(roleType, e.target.checked)}>
                    {roleType}
                  </Checkbox>
                  {role && (
                    <Checkbox
                      style={{ marginLeft: '10px' }}
                      checked={role.is_default}
                      onChange={(e) => handleDefaultChange(roleType, e.target.checked)}
                    >
                      Default
                    </Checkbox>
                  )}
                </div>
              );
            })}
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-plus" />}>
            Create Account
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalCreateAccount;
