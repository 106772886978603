import { useCallback, useEffect, useState } from 'react';

import { Button, Input, message, Modal, Select } from 'antd';

import { feePolicyUpdate } from '@src/clients/3thix';
import { CURRENCY_DESCRIPTIONS } from '@src/constants';
import { Error3thix, FeePolicy, UpdateFeePolicyRequest } from '@src/types';

type Props = {
  feePolicy: FeePolicy | null;
  isOpen: boolean;
  toggleOpen: () => void;
  onRefresh: () => void;
};

const ModalUpdateFee = ({ feePolicy, isOpen, toggleOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<UpdateFeePolicyRequest>({
    id: '',
    provider: '',
    rail: '',
    currency: '',
    buyer_to_seller_amount_percentage: '0.0',
    buyer_to_seller_amount_fixed: '0.0',
    buyer_to_house_amount_percentage: '0.0',
    buyer_to_house_amount_fixed: '0.0',
    seller_to_house_amount_percentage: '0.0',
    seller_to_house_amount_fixed: '0.0',
    pay_provider: 'BUYER',
  });

  useEffect(() => {
    if (feePolicy) {
      setFormData(feePolicy);
    }
  }, [feePolicy]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await feePolicyUpdate(formData);

      if (status === 204) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  const handleChangeSelect = useCallback((value: string, fieldName: string) => {
    setFormData((old) => ({ ...old, [fieldName]: value }));
  }, []);

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-coins"></i> Update Fee Policy
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label htmlFor="id">ID</label>
            <Input
              required
              disabled
              size="large"
              type="text"
              name="id"
              value={formData.id}
              placeholder="Fee ID"
              className="my-2 w-full"
            />

            <label htmlFor="rail">Rail</label>
            <Input
              disabled
              size="large"
              type="text"
              name="rail"
              value={formData.rail}
              placeholder="Rail"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="currency">Currency</label>
            <Input
              disabled
              size="large"
              type="text"
              name="currency"
              value={CURRENCY_DESCRIPTIONS[formData.currency as keyof typeof CURRENCY_DESCRIPTIONS]}
              className="my-2 w-full"
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToSellerAmountPercentage">
              Buyer to Seller Amount Percentage
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_seller_amount_percentage"
              value={formData.buyer_to_seller_amount_percentage}
              placeholder="Buyer to Seller Amount Percentage"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToSellerAmountFixed">
              Buyer to Seller Amount Fixed
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_seller_amount_fixed"
              value={formData.buyer_to_seller_amount_fixed}
              placeholder="Buyer to Seller Amount Fixed"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToHouseAmountPercentage">
              Buyer to House Amount Percentage
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_house_amount_percentage"
              value={formData.buyer_to_house_amount_percentage}
              placeholder="Buyer to House Amount Percentage"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="buyerToHouseAmountFixed">
              Buyer to House Amount Fixed
            </label>
            <Input
              size="large"
              type="text"
              name="buyer_to_house_amount_fixed"
              value={formData.buyer_to_house_amount_fixed}
              placeholder="Buyer to House Amount Fixed"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="sellerToHouseAmountPercentage">
              Seller to House Amount Percentage
            </label>
            <Input
              size="large"
              type="text"
              name="seller_to_house_amount_percentage"
              value={formData.seller_to_house_amount_percentage}
              placeholder="Seller to House Amount Percentage"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="sellerToHouseAmountFixed">
              Seller to House Amount Fixed
            </label>
            <Input
              size="large"
              type="text"
              name="seller_to_house_amount_fixed"
              value={formData.seller_to_house_amount_fixed}
              placeholder="Seller to House Amount Fixed"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label className="text-white font-[10px] ml-[10px]" htmlFor="payProvider">
              Pay Provider
            </label>
            <Select
              size="large"
              value={formData.pay_provider}
              className="my-2 w-full"
              onChange={(value) => handleChangeSelect(value, 'pay_provider')}
            >
              <Select.Option value="BUYER">BUYER</Select.Option>
              <Select.Option value="SELLER">SELLER</Select.Option>
              <Select.Option value="HOUSE">HOUSE</Select.Option>
            </Select>
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-edit" />}>
            Update Fee
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalUpdateFee;
