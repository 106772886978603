import { useCallback, useEffect, useState } from 'react';

import { Button, Flex, message, Skeleton, Table, Tooltip } from 'antd';

import { processorConfigDelete, processorConfigGet } from '@src/clients/3thix';
import { Error3thix, ProcessorConfig } from '@src/types';

import ModalCreateUpdate from './components/ModalCreateUpdate';
import { COLUMNS } from './constants';

type Props = {
  parentEntityID: string;
  entityID: string;
};

const ProcessorConfigs = ({ entityID, parentEntityID }: Props) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [configs, setConfigs] = useState<ProcessorConfig>();
  const [oldConfigs, setOldConfigs] = useState<{
    id: string;
    parent_id: string | null;
    merchant_id: string;
    merchant_site_id: string;
  }>();

  const getOne = useCallback(async () => {
    const { data, status } = await processorConfigGet(entityID);

    if (status === 200) {
      setConfigs(data as ProcessorConfig);
      setIsLoading(false);
      return;
    }

    if (status === 404) {
      setConfigs(undefined);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [entityID]);

  const deleteConfig = useCallback(
    async (id: string) => {
      const { data, status } = await processorConfigDelete(id);

      if (status === 204) {
        getOne();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [getOne]
  );

  const toggleOpen = () => setOpenCreateModal((old) => !old);

  useEffect(() => {
    getOne();
  }, [getOne]);

  console.info('oldConfigs', oldConfigs, 'o', oldConfigs ? oldConfigs.parent_id : parentEntityID);

  return (
    <div className="text-right">
      <Button
        type="default"
        size="large"
        icon={<i className="fas fa-plus" />}
        style={{ marginBottom: '20px' }}
        onClick={toggleOpen}
      >
        New
      </Button>

      {openCreateModal && (
        <ModalCreateUpdate
          oldConfigs={oldConfigs}
          parentEntityID={parentEntityID}
          entityID={entityID}
          isOpen={openCreateModal}
          toggleOpen={toggleOpen}
          onSuccess={getOne}
        />
      )}

      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && !configs && <p>No content found</p>}
        {!isLoading && configs && (
          <Table
            dataSource={[configs]}
            scroll={{ x: '50%' }}
            columns={[
              {
                title: 'Actions',
                key: 'actions',
                render: (_: unknown, record: ProcessorConfig) => (
                  <Flex align="flex-start" gap="small">
                    <Tooltip title="View balance">
                      <Button
                        type="default"
                        size="middle"
                        icon={<i className="fas fa-trash" />}
                        onClick={() => deleteConfig(record.id)}
                      />
                    </Tooltip>
                    <Tooltip title="Edit">
                      <Button
                        type="default"
                        size="middle"
                        icon={<i className="fas fa-edit" />}
                        onClick={() => {
                          setOldConfigs({
                            id: record.id,
                            merchant_id: record.config.merchant_id,
                            parent_id: record.parent_id || null,
                            merchant_site_id: record.config.merchant_site_id,
                          });
                          toggleOpen();
                        }}
                      />
                    </Tooltip>
                  </Flex>
                ),
              },
              ...COLUMNS,
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessorConfigs;
