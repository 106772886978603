import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, message, Skeleton, Table, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';

import { invoiceList } from '@src/clients/3thix';
import { Error3thix, Payment, SuccessInvoicesList } from '@src/types';

import { COLUMNS, PAGE_SIZE } from './constants';

type Props = {
  orderId: string;
};

const InvoicesTable = ({ orderId }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [invoicesResp, setInvoicesResp] = useState<SuccessInvoicesList | null>(null);

  const getInvoices = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await invoiceList(page, pageSize, orderId);

    if (status === 200) {
      setInvoicesResp(data as SuccessInvoicesList);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    message.error((data as Error3thix).message);
  }, [page, pageSize, orderId]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  const rows = useMemo(() => {
    if (invoicesResp === null) {
      return [];
    }

    return invoicesResp.invoices;
  }, [invoicesResp]);

  return (
    <div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && invoicesResp !== null && (
          <Table
            dataSource={rows}
            columns={COLUMNS}
            scroll={{ x: '50%' }}
            expandable={{
              expandedRowRender: (record) => (
                <div className="p-10">
                  <Title level={5}>Payments</Title>
                  <Table
                    dataSource={record.payments}
                    pagination={false}
                    scroll={{ x: '50%' }}
                    columns={[
                      {
                        title: 'Actions',
                        key: 'actions',
                        render: (_: unknown, record: Payment) => (
                          <>
                            <Tooltip title="Ledger">
                              <Button
                                type="default"
                                size="middle"
                                icon={<i className="fas fa-book" />}
                                onClick={() => navigate(`/ledger?transactionId=${record.id}`)}
                              />
                            </Tooltip>
                            <Tooltip title="Chargeback">
                              <Button
                                type="default"
                                size="middle"
                                icon={<i className="fas fa-credit-card" />}
                                onClick={() => navigate(`/chargeback-requests?transferId=${record.transfer_id}`)}
                              />
                            </Tooltip>
                          </>
                        ),
                      },
                      { title: 'Status', dataIndex: 'status' },
                      { title: 'Amount', dataIndex: 'destination_amount' },
                      { title: 'Fees', dataIndex: 'destination_fees' },
                      { title: 'Total', dataIndex: 'destination_total' },
                      { title: 'Currency', dataIndex: 'destination_currency' },
                      { title: 'Source Account', dataIndex: 'source_account_id' },
                      { title: 'Destination Account', dataIndex: 'destination_account_id' },
                      { title: 'ID', dataIndex: 'id' },
                      {
                        title: 'Created at',
                        dataIndex: 'created_at',
                        render: (created_at: string) => {
                          return new Date(created_at).toDateString();
                        },
                      },
                    ]}
                  />
                </div>
              ),
              rowExpandable: (record) => record.payments?.length > 0,
            }}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: invoicesResp.total,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InvoicesTable;
