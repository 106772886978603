import { useCallback, useEffect, useMemo, useState } from 'react';

import { App, message, Select, Skeleton, Table } from 'antd';
import Title from 'antd/es/typography/Title';

import { chargebackList, chargebackUpdate } from '@src/clients/3thix';
import Container from '@src/components/Container';
import { searchTextFilterTable } from '@src/components/SearchFilterTable';
import { Chargeback, Error3thix, ListChargebackResp, SearchChargebackReq, UpdateChargeback } from '@src/types';

const PAGE_SIZE = 20;

const ChargebackRequests = () => {
  const { modal } = App.useApp();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [chargebackRequests, setChargebackRequests] = useState<ListChargebackResp>();
  const [search, setSearch] = useState<SearchChargebackReq>({
    transfer_id: new URLSearchParams(window.location.search).get('transferId') || null,
    processor_entity_id: null,
    status: null,
    order_by: null,
  });

  const listAll = useCallback(async () => {
    const { data, status } = await chargebackList(page, pageSize, search);
    if (status === 200) {
      setChargebackRequests(data as ListChargebackResp);
      setIsLoading(false);
      return;
    }

    message.error((data as Error3thix).message);
  }, [page, pageSize, search]);

  const onChangeSort = useCallback((field: string) => {
    setSearch((old) => {
      if (old.order_by !== null && old.order_by.field === field) {
        return { ...old, order_by: old.order_by.order === 'DESC' ? { field, order: 'ASC' } : null };
      }

      return { ...old, order_by: { field, order: 'DESC' } };
    });
  }, []);

  useEffect(() => {
    listAll();
  }, [listAll]);

  const rows = useMemo(() => {
    return chargebackRequests ? chargebackRequests.chargeback : [];
  }, [chargebackRequests]);

  const changeChargebackInfos = useCallback(
    (req: UpdateChargeback) => {
      modal.confirm({
        title: 'Updating chargeback status',
        content: 'Do you really wanna change this data?',
        onOk: async () => {
          const { status, data } = await chargebackUpdate(req);
          if (status === 204) {
            message.success('success!');
            listAll();
            return;
          }

          message.error((data as Error3thix).message);
        },
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    },
    [listAll, modal]
  );

  return (
    <Container>
      <div className="mb-[20px] flex justify-between">
        <div className="flex">
          <Title level={2}>
            <i className="fa-regular fa-credit-card"></i> Chargeback requests
          </Title>
        </div>
      </div>
      <div>{isLoading && <Skeleton />}</div>
      <div>
        {!isLoading && chargebackRequests && (
          <Table
            dataSource={rows}
            rowKey={(index) => `row-${index}`}
            scroll={{ x: '50%' }}
            columns={[
              {
                title: 'Status',
                dataIndex: 'status',
                width: 150,
                render: (_: unknown, record: Chargeback) => {
                  return (
                    <Select
                      size="large"
                      value={record.status}
                      className="my-2 w-full"
                      onChange={(value) =>
                        changeChargebackInfos({
                          id: record.id,
                          status: value,
                          resolution_status: record.resolution_status,
                        })
                      }
                    >
                      <Select.Option value="FILTERING">FILTERING</Select.Option>
                      <Select.Option value="OPEN">OPEN</Select.Option>
                      <Select.Option value="DISPUTING">DISPUTING</Select.Option>
                      <Select.Option value="CLOSED">CLOSED</Select.Option>
                    </Select>
                  );
                },
                filterDropdown: searchTextFilterTable({
                  setSearchText: (status) => setSearch((old) => ({ ...old, status })),
                  fieldName: 'Status',
                }),
              },
              {
                title: 'Resolution Status',
                dataIndex: 'resolution_status',
                width: 200,

                render: (_: unknown, record: Chargeback) => {
                  return (
                    <Select
                      size="large"
                      value={record.resolution_status || 'NOT DEFINED'}
                      className="my-2 w-full"
                      onChange={(value) =>
                        changeChargebackInfos({
                          id: record.id,
                          status: record.status,
                          resolution_status: value === '' ? null : value,
                        })
                      }
                    >
                      <Select.Option value="">NOT DEFINED YET</Select.Option>
                      <Select.Option value="MERCHANT_WON">MERCHANT WON</Select.Option>
                      <Select.Option value="MERCHANT_LOST">MERCHANT LOST</Select.Option>
                    </Select>
                  );
                },
              },
              { title: 'Amount', dataIndex: 'amount' },
              { title: 'Currency', dataIndex: 'currency' },
              {
                title: 'Payload',
                dataIndex: 'chargeback_payload',
                render: (chargeback_payload: string) => {
                  return (
                    <div
                      className="h-[200px] p-[20px] rounded-[12px] bg-[rgba(0,0,0,0.4)]"
                      style={{ overflow: 'auto' }}
                    >
                      {JSON.stringify(chargeback_payload, null, 1)}
                    </div>
                  );
                },
              },
              { title: 'Processor', dataIndex: 'processor_type' },
              {
                title: 'Merchant Entity',
                dataIndex: 'processor_entity_id',
                filterDropdown: searchTextFilterTable({
                  setSearchText: (processor_entity_id) => setSearch((old) => ({ ...old, processor_entity_id })),
                  fieldName: 'Merchant Entity',
                }),
              },
              { title: 'ID', dataIndex: 'id' },
              {
                title: 'Created at',
                dataIndex: 'created_at',
                render: (created_at: string) => {
                  return new Date(created_at).toDateString();
                },
                sorter: true,
                onHeaderCell: () => ({
                  onClick: () => {
                    onChangeSort('created_at');
                  },
                }),
              },
            ]}
            pagination={{
              position: ['bottomCenter'],
              defaultCurrent: page,
              total: chargebackRequests ? chargebackRequests.total : 0,
              defaultPageSize: pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              onChange: setPage,
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default ChargebackRequests;
