import { useCallback, useEffect, useState } from 'react';

import { Button, Checkbox, Input, message, Modal } from 'antd';

import { accountUpdate } from '@src/clients/3thix';
import { CRYPTO_CURRENCY_LIST, roleTypes } from '@src/constants';
import { AccountRole, Error3thix } from '@src/types';

type AccountData = {
  id: string;
  name: string;
  provider: string;
  rail: string;
  currency: string;
  roles: AccountRole[];
};

type Props = {
  account: AccountData | null;
  isOpen: boolean;
  toggleOpen: () => void;
  onRefresh: () => void;
};

const ModalUpdateAccount = ({ account, isOpen, toggleOpen, onRefresh }: Props) => {
  const [formData, setFormData] = useState<AccountData>({
    id: '',
    name: '',
    provider: '',
    rail: '',
    currency: '',
    roles: [] as AccountRole[],
  });

  useEffect(() => {
    if (account) {
      setFormData(account);
    }
  }, [account]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleAccountRoleChange = useCallback((roleType: string, isChecked: boolean) => {
    setFormData((old) => {
      const roles = [...old.roles];
      if (isChecked) {
        roles.push({ type: roleType, is_default: false });
      } else {
        const index = roles.findIndex((role) => role.type === roleType);
        if (index !== -1) roles.splice(index, 1);
      }
      return { ...old, roles };
    });
  }, []);

  const handleDefaultChange = useCallback((roleType: string, isDefault: boolean) => {
    setFormData((old) => {
      const roles = old.roles.map((role) => (role.type === roleType ? { ...role, is_default: isDefault } : role));
      return { ...old, roles };
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { status, data } = await accountUpdate(formData);

      if (status === 200) {
        onRefresh();
        toggleOpen();
        return;
      }

      message.error((data as Error3thix).message);
    },
    [formData, onRefresh, toggleOpen]
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      title={
        <>
          <i className="fa-solid fa-user-edit"></i> Update Account
        </>
      }
      onOk={toggleOpen}
      onCancel={toggleOpen}
    >
      <div className="w-full mt-5">
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label htmlFor="id">ID</label>
            <Input
              required
              disabled
              size="large"
              type="text"
              name="id"
              value={formData.id}
              placeholder="Account ID"
              className="my-2 w-full"
            />

            <label htmlFor="name">Name</label>
            <Input
              required
              size="large"
              type="text"
              name="name"
              value={formData.name}
              placeholder="Account Name"
              className="my-2 w-full"
              onChange={handleChange}
            />
          </div>

          <div className="w-full">
            <label htmlFor="provider">Provider</label>
            <Input
              disabled
              size="large"
              type="text"
              name="provider"
              value={formData.provider}
              placeholder="Provider"
              className="my-2 w-full"
            />
          </div>

          <div className="w-full">
            <label htmlFor="rail">Rail</label>
            <Input
              disabled
              size="large"
              type="text"
              name="rail"
              value={formData.rail}
              placeholder="Rail"
              className="my-2 w-full"
            />
          </div>

          <div className="w-full">
            <label htmlFor="currency">Currency</label>
            <Input
              disabled
              size="large"
              type="text"
              name="currency"
              value={CRYPTO_CURRENCY_LIST[formData.currency as keyof typeof CRYPTO_CURRENCY_LIST]}
              className="my-2 w-full"
            />
          </div>

          <div className="w-full">
            <label htmlFor="roles">Account Roles</label>
            {roleTypes.map((roleType) => {
              const role = formData.roles.find((r) => r.type === roleType);
              return (
                <div key={roleType} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Checkbox checked={!!role} onChange={(e) => handleAccountRoleChange(roleType, e.target.checked)}>
                    {roleType}
                  </Checkbox>
                  {role && (
                    <Checkbox
                      style={{ marginLeft: '10px' }}
                      checked={role.is_default}
                      onChange={(e) => handleDefaultChange(roleType, e.target.checked)}
                    >
                      Default
                    </Checkbox>
                  )}
                </div>
              );
            })}
          </div>

          <Button size="large" className="my-2" htmlType="submit" icon={<i className="fas fa-edit" />}>
            Update Account
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ModalUpdateAccount;
