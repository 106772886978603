import { ReactElement } from 'react';

import Breadcrumbs from '@src/components/Container/components/Breadcrumbs';
import { Breadcrumb } from '@src/components/Container/components/Breadcrumbs/types';
import Menu from '@src/components/Container/components/Menu';

type Props = {
  children: ReactElement[] | ReactElement;
  breadcrumbs?: Breadcrumb[];
};

const Container = ({ children, breadcrumbs }: Props) => {
  return (
    <div className="flex flex-col p-20 w-full">
      <div className="flex flex-row justify-between mb-8">
        <img src="/logo.png" alt="logo" className="flex w-[120px] h-[auto]" />
        <Menu />
      </div>

      <div className="w-full rounded-[10px] mt-8">
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {children}
      </div>
    </div>
  );
};

export default Container;
